import $ from 'jquery'
// let $map

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

const countUp = $x => {
  $x.find('.js-counter').each(function() {
    var $this = $(this),
      countTo = $this.attr('data-count')
    $this.text(0)
    $({ countNum: $this.text() }).animate(
      {
        countNum: countTo,
      },
      {
        duration: 2000,
        easing: 'linear',
        step: function() {
          $this.text(numberWithCommas(Math.floor(this.countNum)))
        },
        complete: function() {
          $this.text(numberWithCommas(this.countNum))
        },
      }
    )
  })
}

$(document).ready(function() {
  var $currentTab = $('a[data-toggle="tab"].active')
  $($currentTab.attr('href'))
    .find('#interactive-map #' + $currentTab.data('id') + ' .map')
    .addClass('active')
  countUp($($currentTab.attr('href')).find('.countries2-facts'))

  $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
    var mapId = $(e.target).data('id')
    var $targetContent = $($(e.target).attr('href'))
    countUp($targetContent)
    $targetContent
      .find('#interactive-map #' + mapId + ' .map')
      .addClass('active')
  })
})
