// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_matchHeight.js'; import './autoload/_sumoselect.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/career_stories/main.js'; import '../vc_elements/case_teaser/main.js'; import '../vc_elements/contact_map/main.js'; import '../vc_elements/countries_2/main.js'; import '../vc_elements/countries/main.js'; import '../vc_elements/enlarging_blocks/main.js'; import '../vc_elements/expandable_teaserbox/main.js'; import '../vc_elements/icon_block/main.js'; import '../vc_elements/newsroom_masonry/main.js'; import '../vc_elements/page_anchors/main.js'; import '../vc_elements/press_grid_react/main.js'; import '../vc_elements/press_grid/main.js'; import '../vc_elements/profile_tabs/main.js'; import '../vc_elements/teaserbox/main.js'; import '../vc_elements/template_element/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
