import 'jquery-match-height/dist/jquery.matchHeight'

$(document).ready(function() {
  $('.enlarging-blocks__item, .enlarging-blocks__content').matchHeight({
    byRow: false,
  })
  $('.js-enlarge-item').on('click', function() {
    var $this = $(this)
    var $section = $this.closest('.enlarging-blocks')
    if (!$this.hasClass('opened')) {
      if (!$this.data('height')) {
        $this.data('height', $this.outerHeight())
      }
      $this
        .css({
          width: $section.innerWidth(),
          height: $section.innerHeight(),
        })
        .addClass('opened')
    } else {
      $this.css({
        width: '',
        height: $this.data('height'),
      })
      setTimeout(function() {
        $this.removeClass('opened')
      }, 200)
    }
    console.log('enlarge!!!!') // eslint-disable-line
  })
})
