import $ from 'jquery'
let $map

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

const countUp = $x => {
  $x.find('.js-counter').each(function() {
    var $this = $(this),
      countTo = $this.attr('data-count')
    $this.text(0)
    $({ countNum: $this.text() }).animate(
      {
        countNum: countTo,
      },
      {
        duration: 2000,
        easing: 'linear',
        step: function() {
          $this.text(numberWithCommas(Math.floor(this.countNum)))
        },
        complete: function() {
          $this.text(numberWithCommas(this.countNum))
        },
      }
    )
  })
}

$(document).ready(function() {
  $map = $('.countries #interactive-map')
  $('.js-country').on('click', function(e) {
    e.preventDefault()
    var $country = $(e.currentTarget)
    var mapId = $(e.currentTarget).data('id')
    if (mapId && !$country.hasClass('active')) {
      if (mapId) {
        selectCountry(mapId)
      }
    } else {
      resetMap()
    }
  })

  $map.on('click', '.map-country', function() {
    var $country = $(this)
    if (!$country.hasClass('active')) {
      selectCountry($country.attr('id'))
    } else {
      resetMap()
    }
  })
})

function resetMap() {
  $('.js-country, .js-more-button').removeClass('hidden active')
  $('.popup').removeClass('active')
  $('.js-country.js-reset').addClass('active')
  $map.find('.map-country').removeClass('active')
  $map.removeClass('selected shift')
}

function selectCountry(country) {
  var $popup = $('.popup[data-id="' + country + '"]')
  var $listCountry = $('.js-country[data-id="' + country + '"]')
  var $mapCountry = $map.find('#' + country)
  $('.popup').removeClass('active')
  $popup.addClass('active')
  countUp($popup)
  $mapCountry
    .siblings()
    .removeClass('active')
    .end()
    .addClass('active')
  $listCountry
    .siblings()
    .removeClass('active')
    .addClass('hidden')
    .end()
    .removeClass('hidden')
    .addClass('active')
  if (!$map.hasClass('selected')) {
    $map.addClass('selected')
  }
  if ($popup.length) {
    $map.addClass('shift')
  } else {
    $map.removeClass('shift')
  }
  console.log(country) // eslint-disable-line
}
