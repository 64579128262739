import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class HomeSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var settings = {
      dots: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      draggable: false,
      swipe: false,
    }

    return (
      <Slider {...settings}>
        {this.props.slides.map((slide, i) => (
          <div
            className="item"
            key={i}
            style={{ backgroundImage: 'url(' + slide.image + ')' }}
          />
        ))}
      </Slider>
    )
  }
}

HomeSlider.propTypes = {
  slides: PropTypes.array,
}

export default HomeSlider
