// import $ from 'jquery'
import 'packery/dist/packery.pkgd.min'

$(window).load(function() {
  $('.posts-grid').packery({
    layoutMode: 'packery',
    itemSelector: '.posts-grid__item',
    gutter: '.gutter-sizer',
  })
})
