import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class PostsSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var settings = {
      dots: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      autoplay: true,
    }

    if (this.props.slides.length) {
      return (
        <Slider {...settings}>
          {this.props.slides.map((slide, i) => (
            <div
              className="slide"
              key={i}
              style={{ backgroundImage: 'url(' + slide.image + ')' }}
            >
              <div className="slide__wrapper">
                <a className="slide__caption" href={slide.link}>
                  <div className="slide__posttype">{slide.post_type}</div>
                  <h2 className="slide__title">{slide.title}</h2>
                  <span className="slide__link button link icon">
                    {this.props.strings.read_more}
                    <i className="btr bt-arrow-right" />
                  </span>
                </a>
              </div>
            </div>
          ))}
        </Slider>
      )
    } else {
      return <div>no slides - no slider</div>
    }
  }
}

PostsSlider.propTypes = {
  slides: PropTypes.array,
  strings: PropTypes.object,
}

export default PostsSlider
