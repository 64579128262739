import stickElement from '../components/stickit'
import 'jquery-drilldown'

export default {
  init() {
    // var $banner = $('header.banner')

    function smooth_scroll(link, e) {
      if (e) e.preventDefault()
      var hash = false
      if (!link && window.location.hash) {
        hash = window.location.hash
      } else if (link && link.indexOf('#') == 0) {
        hash = link
      } else if (
        link &&
        location.pathname.replace(/^\//, '') ==
          link.pathname.replace(/^\//, '') &&
        location.hostname == link.hostname
      ) {
        hash = link.hash
      }
      if (hash) {
        $('html, body').animate(
          {
            scrollTop: $(hash).offset().top - 110,
          },
          600
        )
        history.replaceState(null, null, hash)
      }
      $(window).trigger('hashchange')
    }

    function setMobileClass() {
      if ($(window).outerWidth() <= 991) {
        $('body').addClass('mobile')
      } else {
        $('body').removeClass('mobile')
      }
    }

    function gfFieldsScript() {
      // Gravity Form: hide label when input is in focus
      // Handling Gravity Form input labels
      $(
        'input[type=text], input[type=tel], input[type=email], input[type=number], textarea, .ginput_container_select select'
      ).keypress(function() {
        $(this)
          .parents('.gfield')
          .addClass('focused')
        $(this)
          .parents('.dropdown-with-text-input, .input-search, .input-variation')
          .addClass('focused')
      })

      $(
        'input[type=text], input[type=tel], input[type=email], input[type=number], textarea, .ginput_container_select select'
      ).each(function() {
        if ($(this).val().length > 0) {
          $(this)
            .parents('.gfield')
            .addClass('focused')
          $(this)
            .parents(
              '.dropdown-with-text-input, .input-search, .input-variation'
            )
            .addClass('focused')
        }
      })

      $(
        'input[type=text], input[type=tel], input[type=email], input[type=number], textarea, .ginput_container_select select'
      ).focusout(function() {
        if ($(this).val().length === 0) {
          $(this)
            .parents('.gfield')
            .removeClass('focused')
          $(this)
            .parents(
              '.dropdown-with-text-input, .input-search, .input-variation'
            )
            .removeClass('focused')
        }
      })
    }

    $(document).ready(function() {
      /* eslint-disable */
      $(
        'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"])'
      ).on('click', function(e) {
        smooth_scroll($(this).attr('href'), e)
      })
      /* eslint-enable */
      if (window.location.hash) {
        smooth_scroll(window.location.hash)
      }

      stickElement('.banner .navbar')
      stickElement('.page-anchors')
      // stickElement('.contact-person')
      // stickElement('#page-contents')

      // Gravity Form: Put the Submit button into 'gform_body' container
      $('.gform_column')
        .last()
        .append($('.gform_footer'))

      gfFieldsScript()

      $('.drilldown').drilldown()

      $('.drilldown .menu-item-has-children > a').on('click', function(e) {
        e.preventDefault()
      })

      // Calls the selectBoxIt method for default HTML select box
      $('select').SumoSelect()

      $('.gform_wrapper').addClass('loaded')
    })

    // Gravity Form: Keep the Submit button into 'gform_body' container after form submitting
    $(document).bind('gform_post_render', function() {
      $('.gform_column')
        .last()
        .append($('.gform_footer'))

      gfFieldsScript()
    })

    function resizeFullWidthRow() {
      $('[data-vc-full-width="true"]').each(function() {
        $(this).css({
          width: document.body.clientWidth,
          marginLeft: -document.body.clientWidth / 2,
        })
        if (!$(this).attr('data-vc-stretch-content')) {
          // let padding =
          //   (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
          // $(this).css({
          //   'padding-left': padding,
          //   'padding-right': padding,
          // })
        }
      })
    }

    resizeFullWidthRow()

    $(window).on('resize', function() {
      resizeFullWidthRow()
      setMobileClass()
    })

    // Scroll to top
    // defining elements
    function getScrollWindow() {
      var e = document.documentElement,
        o = document.body,
        t = e.scrollTop || (o && o.scrollTop) || 0
      return (t -= e.clientTop)
    }

    var toTop = $('.to-top')

    $(document).ready(function() {
      // Listening for click event
      $('.to-top').on('click', function(e) {
        e.preventDefault()
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          600
        )
      })
      setMobileClass()
    })

    $('a').on('click', function() {
      if (
        $(this)
          .attr('href')
          .includes('#uc-corner-modal-show')
      ) {
        // eslint-disable-next-line
        UC_UI.showSecondLayer()
      }
    })

    $(window).on('scroll', function() {
      // Detecting when to show the button
      if (
        getScrollWindow() > $(window).outerHeight() / 2 &&
        !toTop.hasClass('visible')
      ) {
        toTop.addClass('visible')
      } else if (
        getScrollWindow() <= $(window).outerHeight() / 2 &&
        toTop.hasClass('visible')
      ) {
        toTop.removeClass('visible')
      }
    })

    $('.banner [data-toggle="collapse"]').on('click', function() {
      $(this)
        .siblings('[data-toggle="collapse"][aria-expanded="true"]')
        .trigger('click')
    })

    var timer
    // Menu dropdown on hover
    $('.nav-item.dropdown').hover(
      function() {
        var $that = $(this)
        if (timer) {
          clearTimeout(timer)
        }
        if (!$that.hasClass('show') && !$('body').hasClass('mobile')) {
          $that.find('.dropdown-toggle').trigger('click')
        }
      },
      function() {
        var $that = $(this)
        if ($that.hasClass('show') && !$('body').hasClass('mobile')) {
          timer = setTimeout(function() {
            $that.find('.dropdown-toggle').trigger('click')
          }, 500)
        }
      }
    )
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
