'use strict'

let stickOffset = 0
let stickInterval
let stickClass = 'set-sticky'

/**
 * stickElement
 * @param  {string} selector jquery selector
 * @return void
 */
export default function stickElement(selector, options = {}) {
  setStick(selector, options)
  $(window).on('resize scroll', function() {
    setStick(selector, options)
  })
}

function setStick(selector) {
  $(selector).each(function(index, el) {
    if (!$(el).closest('[class*="__holder"]').length) {
      $(el).wrap('<div class="set-sticky__holder"></div>')
      $(el)
        .closest('[class*="__holder"]')
        .css({
          marginTop: $(el).css('margin-top'),
          marginBottom: $(el).css('margin-bottom'),
        })
    }
    if (!$(el).data('initial-offset') && $(el).data('initial-offset') != 0) {
      $(el).data('initial-offset', $(el).offset().top)
    }
    if (!$(el).data('initial-height')) {
      $(el).data('initial-height', $(el).outerHeight())
    }
    $(el)
      .css({
        width: $(el)
          .closest('[class*="__holder"]')
          .width(),
      })
      .closest('[class*="__holder"]')
      .css({
        height: $(el).data('initial-height'),
      })

    if (
      $(window).scrollTop() + stickOffset > $(el).data('initial-offset') &&
      !$(el).hasClass(stickClass)
    ) {
      $(el)
        .addClass(stickClass)
        .css({
          marginTop: 0,
          marginBottom: 0,
          position: 'fixed',
          top: stickOffset,
        })
      // updateStickOffset()
    } else if (
      $(window).scrollTop() + stickOffset - $(el).data('actual-height') <=
        $(el).data('initial-offset') &&
      $(el).hasClass(stickClass)
    ) {
      $(el)
        .removeClass(stickClass)
        .css({
          marginTop: '',
          marginBottom: '',
          position: '',
          top: '',
        })
    }
    // console.log($(el).data('initial-offset')) //eslint-disable-line

    if (typeof stickInterval === 'undefined') {
      stickInterval = setInterval(function() {
        updateStickOffset()
      }, 10)
      // window.requestAnimationFrame(updateStickOffset)
    }
  })
}

function updateStickOffset() {
  stickOffset = 0
  $('.set-sticky').each(function(index, el) {
    $(el).data('actual-height', $(el).outerHeight())
    $(el).css({ top: stickOffset })
    stickOffset += $(el).outerHeight()

    if (
      $(window).scrollTop() + stickOffset - $(el).data('actual-height') <=
        $(el).data('initial-offset') &&
      $(el).hasClass(stickClass)
    ) {
      $(el)
        .removeClass(stickClass)
        .css({
          marginTop: '',
          marginBottom: '',
          position: '',
          top: '',
        })
    }
  })
  //console.log(stickOffset) // eslint-disable-line
}

// Using:
// import stickElement from '../components/stickit'
// stickElement([selector])
