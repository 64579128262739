import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class TimelineSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var settings = {
      dots: true,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 2,
      adaptiveHeight: false,
      autoplay: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    }

    if (this.props.slides.length) {
      return (
        <Slider {...settings}>
          {this.props.slides.map((slide, i) => (
            <div className="slide " key={i}>
              <div className="timeline__year">
                <div className="timeline__title">{slide.year}</div>
                <div className="timeline__image-wrapper">
                  <div
                    className="timeline__image"
                    style={{ backgroundImage: 'url(' + slide.image + ')' }}
                  />
                </div>
                <div className="timeline__description">{slide.description}</div>
              </div>
            </div>
          ))}
        </Slider>
      )
    } else {
      return <div>no slides - no slider</div>
    }
  }
}

TimelineSlider.propTypes = {
  slides: PropTypes.array,
}

export default TimelineSlider
