// $('.profile-tabs__list').slick({})
// $('.profile-tabs__list').carousel()
var $tabsCarousel = $('.profile-tabs__carousel')

$(window).on('resize', function() {
  $tabsCarousel.carousel({
    interval: false,
  })
  // if ($(window).outerWidth() >= 768) {
  //   $tabsCarousel.carousel('dispose')
  // } else {
  //   $tabsCarousel.carousel({
  //     interval: false,
  //   })
  // }
})

$tabsCarousel.find('[data-slide="next"]').on('click', function(e) {
  e.preventDefault()
  $tabsCarousel.carousel('next')
})

$tabsCarousel.find('[data-slide="prev"]').on('click', function(e) {
  e.preventDefault()
  $tabsCarousel.carousel('prev')
})
